<template>
    <html>
    <head>
    <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport"       content="width=device-width, initial-scale=1.0">
     <meta name="description"    content="Instabill Pro automatically create Tax Invoices for payments received from Razorpay, Instamojo & Stripe. Auto-Send to customers over Email or WhatsApp..">
        <meta property="og:title" content=" Do Online Tax Invoices Automatically | Signup Now">
        <!-- Site title -->
        <title>InstaBill Pro - GST Billing Invoice maker/ generator | Accounting software App online</title>
    <meta name="facebook-domain-verification" content="3kkacuhnt4p64z8k8g32cb0swcw79u" />
        <link rel="shortcut icon" href="https://instabill.co/public/front/images/favicon.png" />
       <link rel='stylesheet' href='https://instabill.co/public/js/bootstrap.min.css' defer>
          <link href="https://instabill.co/public/web_home/css/style.css" rel="stylesheet">
    
     <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" defer>
 
    
    </head>
   
    <body>
    
        <div class="book">
            <div class="container1">
                <div class="lefta">
                    <img src="https://instabill.co/public/front/images/men.png">
                    
                    
                     <div class="slider">
        <div class="slide-track">
            <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> Tally Automation
            </div>
            <div class="slide">
            <i class="fa fa-handshake-o" aria-hidden="true"></i> Earn Commissions 
            </div>
            <div class="slide">
            <i class="fa fa-check" aria-hidden="true"></i> GST Filling 
            </div>
            <div class="slide">
            <i class="fa fa-check" aria-hidden="true"></i> GST JSON
            </div>
            <div class="slide">
            <i class="fa fa-file-text-o" aria-hidden="true"></i> Bank Statement Import
            </div>
        <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> Tally Automation
            </div>
            <div class="slide">
            <i class="fa fa-handshake-o" aria-hidden="true"></i> Earn Commissions 
            </div>
            <div class="slide">
            <i class="fa fa-check" aria-hidden="true"></i> GST Filling 
            </div>
            <div class="slide">
            <i class="fa fa-check" aria-hidden="true"></i> GST JSON
            </div>
            <div class="slide">
            <i class="fa fa-file-text-o" aria-hidden="true"></i> Bank Statement Import
            </div>
        
        </div>
    </div>
    
    
    
    <div class="slider two">
        <div class="slide-track">
            
            <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> GSTR 1 Sync
            </div>
            <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> GSTR 3 Sync
            </div>
            <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> Sale Sync
            </div>
            <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> Purchase Sync 
            </div>
        <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> GSTR 1 Sync
            </div>
            <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> GSTR 3 Sync
            </div>
            <div class="slide">
                <i class="fa fa-refresh" aria-hidden="true"></i> Sale Sync
            </div>
            <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> Purchase Sync 
            </div>
        </div>
    </div>
    
    
     <div class="slider three">
        <div class="slide-track">
                <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> Expense Sync
            </div>
            <div class="slide">
            <i class="fa fa-file-text" aria-hidden="true"></i> Manage Document 
            </div>
            <div class="slide">
                <i class="fa fa-handshake-o" aria-hidden="true"></i> One Click Accounting
            </div>
              <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> Expense Sync
            </div>
            <div class="slide">
            <i class="fa fa-file-text" aria-hidden="true"></i> Manage Document 
            </div>
            <div class="slide">
                <i class="fa fa-handshake-o" aria-hidden="true"></i> One Click Accounting
            </div>
            <div class="slide">
            <i class="fa fa-refresh" aria-hidden="true"></i> Expense Sync
            </div>
            <div class="slide">
            <i class="fa fa-file-text" aria-hidden="true"></i> Manage Document 
            </div>
            <div class="slide">
                <i class="fa fa-handshake-o" aria-hidden="true"></i> One Click Accounting
            </div>
            </div>
            </div>
               
               
               
                <div class="slider">
        <div class="slide-track">
                <div class="slide">
            <i class="fa fa-plug" aria-hidden="true"></i> GST UPI Payment 
            </div>
            <div class="slide">
            <i class="fa fa-handshake-o" aria-hidden="true"></i> AI based Accounting
            </div>
            <div class="slide">
                <i class="fa fa-file-word-o" aria-hidden="true"></i> Business Report
            </div>
            <div class="slide">
            <i class="fa fa-plug" aria-hidden="true"></i> GST UPI Payment 
            </div>
            <div class="slide">
            <i class="fa fa-handshake-o" aria-hidden="true"></i> AI based Accounting
            </div>
            <div class="slide">
                <i class="fa fa-file-word-o" aria-hidden="true"></i> Business Report
            </div>
            <div class="slide">
            <i class="fa fa-plug" aria-hidden="true"></i> GST UPI Payment 
            </div>
            <div class="slide">
            <i class="fa fa-handshake-o" aria-hidden="true"></i> AI based Accounting
            </div>
            <div class="slide">
                <i class="fa fa-file-word-o" aria-hidden="true"></i> Business Report
            </div>
            </div>
            </div>  
    
    
                </div>
                <div class="righta">
                    <img src="https://instabill.co/public/instabillpro_logo.png">
                     <img src="https://instabill.co/public/front/images/lines.png" class="lines">
                    <h2>Book Your Free Demo</h2>
                    <p>Instabill Pro is GST Compliant Billing Software to create and share Invoice, Quotation, Purchase Order, Delivery Challan, eInvoice, eWaybill. Collect online payments, Records Purchase and Expenses, GST Reports, Business Reports and much more.....</p>
                        <form v-on:submit.prevent="demosubmit">
                        <div class="one">
                            <input type="text" placeholder="Name *" v-model="name" required>
                        </div>
                        <div class="one">
                            <input type="email" placeholder="Email id *" v-model="email" required>
                        </div>
                        <div class="one" style="">
                            <input type="number" placeholder="Mobile Number *" v-model="mobile" required>
                        </div>
                        <div class="one">
                            <input type="text" placeholder="Nature of Business *" v-model="nature_of_business" required>
                        </div>
                       
                        
                        
                        <p style="    font-size: 14px;
        margin: 0px;
        /* display: flex; */
        /* align-items: center; */
        width: 100%;
        margin-bottom: 4%;
        font-weight: 500;
        margin-top: 5%;">
                            <input type="checkbox" style="    margin-right: 5px;"> By submitting this form, you agree to the processing of personal data according to our<a href="https://instabill.co/privacy.html" target="_blank"> Privacy Policy.</a>
                        </p>
                        
                        <div class="form-group">
                                
                                 
                                        <div class="fxt-transformY-50 fxt-transition-delay-6" style="">
                                            <br>
                                         <button type="submit">SCHEDULE MY DEMO <img src="https://instabill.co/public/front/images/loader.gif" id="load_show" class="load_show" style="    width: 15px;
        margin: 0px;
        margin-left: 2%;"></button>
                                        </div>
                                    </div>
                                    
                        
                       
                    </form>
                </div>
            </div>
        </div>
    

        </body>
        </html> 
</template>

  
<script>
import {
    checkEmail,
    postApi
} from "../assets/js/helper.js";
export default {
    name: "bookdemo",
    data() {
        return {
            email: "",
            name:"",
            mobile:"",
            nature_of_business:"",
            loader: false,
            message: false,
            alertMessage: "",
            alertClass: "",

        };
    },
    methods: {
        async demosubmit() {

            if (this.name == "") {
                this.alertMessage = "Please Enter Your Name";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }


            if (this.email == "") {
                this.alertMessage = "Please Enter Email ID";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }

            
            //check email is valid
            if (!checkEmail(this.email)) {
                this.alertMessage = "Please enter valid email";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }

            
            if (this.mobile == "") {
                this.alertMessage = "Please Enter Your mobile";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }



            this.loader = true;
            const response = await postApi(
                "/bookdemos", {
                    email: this.email,
                    name:this.name,
                    mobile:this.mobile,
                    nature_of_business:this.nature_of_business
                }, {}
            );
            try {
               console.log(response);
                this.$router.push("/thankyou");

            } catch (err) {
                alert(err);
            }
        },
         close_msg() {
             this.message = false;
        },
    },
};
</script>




<style type="text/css">
*{margin: 0px;padding: 0px}
.slider {
    background: transparent;
}
.lefta {
    width: 40%;
float: left;
}.footer-widget-area.section-big {
padding-bottom: 4%;
}
.icon i {
color: #f27013;
}

.r h4 {
 color: #000;
margin-bottom: 2px;
font-size: 15px;
font-weight: 600;
}
.icon {
 width: 12%;
float: left;
display: flex;
align-items: center;
justify-content: center;
font-size: 29px;
height: 53px;
}
.righta h2 {
   color: #000;
font-size: 40px;
font-weight: 600;
margin-top: 7px;
}

.righta p {
font-size: 16px;
}
.one label {
display: block;
}
.righta img {
  width: 168px;
margin-bottom: 4%;
}
.one {
    margin-top: 5%;
margin-left: 0px;
}
.one input {
width: 100%;
border: 1px solid #d1d1d1;
padding: 12px 12px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 3px;
box-sizing: border-box;
font-size: 14px;
font-family: var(--zf-primary-regular);
appearance: none !important;
-moz-appearance: none !important;
-webkit-appearance: none !important;
}
.book {background:linear-gradient(85deg, #f66a59, #ffae00);
padding: 6% 0%;
}
.righta {
position: relative;
width: 60%;
float: left;
background: #f1f7f8;
padding: 5% 6%;
border-radius: 25px;
height:877px;
}
.r {
   width: 84%;
float: right;
}
.boa {
overflow: hidden;
margin-bottom: 8%;
margin-left: 8%;
}
form button {
  background: #f27013;
color: #fff;
border: 0px;
padding: 7px 1% 7px;
width: 100%;
font-size: 18px;
text-transform: uppercase;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
}
.lefta img {    border-radius: 20px 0px 0px 0px;
padding-bottom: 2%;
}
.container1 {
width: 80%;
margin: auto;
background: #fff;
overflow: hidden;
border-radius: 20px 20px 20px 20px;
}
img.lines {
position: absolute;
right: 0%;
top: 0%;
}
.one select {
width: 100%;
border: 1px solid #d1d1d1;
padding: 12px 12px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 3px;
box-sizing: border-box;
font-size: 14px;
font-family: var(--zf-primary-regular);
appearance: none !important;
-moz-appearance: none !important;
-webkit-appearance: none !important;
}



/*pooja*/
.marquee1 div span i {
margin-right: 3%;
color: #fff;
}
.marquee1 {
   width: 100%;
overflow: hidden;
position: relative;
height: 50px;
margin-bottom: 18px;
margin-top: 5%;
}

.marquee1 div {
display: block;
width: 200%;
height: 39px;
position: absolute;
overflow: hidden;
animation: marquee 15s linear infinite;
}

.marquee1 span {
float: left;
width: 10%;
width: 338px;
margin: 0% 8px;
background: #f27013;
color: #fff;
padding: 6px;
border-radius: 5px;
box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
display: flex;
align-items: center;
justify-content: center;
font-size: 19px;
}

@keyframes  marquee {
0% { right: 0; }
100% { right: -100%; }
}
.third h1 {
z-index: 2;
color: #000;
letter-spacing: -.04em;
margin-top: 0;
margin-bottom: 20px;
font-size: 46px;
font-weight: 700;
line-height: 1.1;
position: relative;
text-align: center;
margin-bottom: 3%;
}
.testim {width: 100%;}
.testim .wrap {position: relative;width: 100%;max-width: 900px;padding: 40px 20px;margin: auto;border-radius: 10px;background: #fff;}
.testim .arrow {display: block;position: absolute;color: #f97f3f;cursor: pointer;font-size: 2em;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-o-transform: translateY(-50%);transform: translateY(-50%);-webkit-transition: all .3s ease-in-out;-ms-transition: all .3s ease-in-out;-moz-transition: all .3s ease-in-out;-o-transition: all .3s ease-in-out;transition: all .3s ease-in-out;padding: 5px;z-index: 22222222;}
.testim .arrow:before {cursor: pointer;}
.testim .arrow:hover {color: #ea830e;}
.testim .arrow.left {left: 10px;}
.testim .arrow.right {right: 10px;}
.testim .dots {text-align: center;position: absolute;width: 100%;bottom: 60px;left: 0;display: block;z-index: 3333;height: 12px;}
.testim .dots .dot {list-style-type: none;display: inline-block;width: 12px;height: 12px;border-radius: 50%;border: 1px solid #eee;margin: 0 10px;cursor: pointer;-webkit-transition: all .5s ease-in-out;    -ms-transition: all .5s ease-in-out;    -moz-transition: all .5s ease-in-out;    -o-transition: all .5s ease-in-out;    transition: all .5s ease-in-out;position: relative;}
.testim .dots .dot.active,
.testim .dots .dot:hover {background: #f97f3f;border-color: #f97f3f;}
.testim .dots .dot.active {-webkit-animation: testim-scale .5s ease-in-out forwards;   -moz-animation: testim-scale .5s ease-in-out forwards;   -ms-animation: testim-scale .5s ease-in-out forwards;   -o-animation: testim-scale .5s ease-in-out forwards;   animation: testim-scale .5s ease-in-out forwards;   }
.testim .cont {position: relative;overflow: hidden;}
.testim .cont > div {text-align: center;position: absolute;top: 0;left: 0;padding: 0 0 70px 0;opacity: 0;}
.testim .cont > div.inactive {opacity: 1;}
.testim .cont > div.active {position: relative;opacity: 1;}
.testim .cont div .img img {display: block;width: auto;height: auto;margin: auto;border-radius: 50%;}
.testim .cont div h2 {color: #ea830e;font-size: 1em;margin: 15px 0;}
.testim .cont div p {font-size: 1.15em;color: #333;width: 80%;margin: auto;}
.testim .cont div.active .img img {-webkit-animation: testim-show .5s ease-in-out forwards;            -moz-animation: testim-show .5s ease-in-out forwards;            -ms-animation: testim-show .5s ease-in-out forwards;            -o-animation: testim-show .5s ease-in-out forwards;            animation: testim-show .5s ease-in-out forwards;            }
.testim .cont div.active h2 {-webkit-animation: testim-content-in .4s ease-in-out forwards;    -moz-animation: testim-content-in .4s ease-in-out forwards;    -ms-animation: testim-content-in .4s ease-in-out forwards;    -o-animation: testim-content-in .4s ease-in-out forwards;    animation: testim-content-in .4s ease-in-out forwards;    }
.testim .cont div.active p {-webkit-animation: testim-content-in .5s ease-in-out forwards;    -moz-animation: testim-content-in .5s ease-in-out forwards;    -ms-animation: testim-content-in .5s ease-in-out forwards;    -o-animation: testim-content-in .5s ease-in-out forwards;    animation: testim-content-in .5s ease-in-out forwards;    }
.testim .cont div.inactive .img img {-webkit-animation: testim-hide .5s ease-in-out forwards;-moz-animation: testim-hide .5s ease-in-out forwards;            -ms-animation: testim-hide .5s ease-in-out forwards;            -o-animation: testim-hide .5s ease-in-out forwards;            animation: testim-hide .5s ease-in-out forwards;            }
.testim .cont div.inactive h2 {-webkit-animation: testim-content-out .4s ease-in-out forwards;        -moz-animation: testim-content-out .4s ease-in-out forwards;        -ms-animation: testim-content-out .4s ease-in-out forwards;        -o-animation: testim-content-out .4s ease-in-out forwards;        animation: testim-content-out .4s ease-in-out forwards;        }
.testim .cont div.inactive p {-webkit-animation: testim-content-out .5s ease-in-out forwards;    -moz-animation: testim-content-out .5s ease-in-out forwards;    -ms-animation: testim-content-out .5s ease-in-out forwards;    -o-animation: testim-content-out .5s ease-in-out forwards;    animation: testim-content-out .5s ease-in-out forwards;    }
@-webkit-keyframes testim-scale {
0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee; }
35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}
@-moz-keyframes testim-scale {
0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}
@-ms-keyframes testim-scale {
0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}
@-o-keyframes testim-scale {
0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}
@keyframes    testim-scale {
0% {
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}
@-webkit-keyframes testim-content-in {
from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

to {
    opacity: 1;
    -webkit-transform: translateY(0);        
    transform: translateY(0);        
}
}
@-moz-keyframes testim-content-in {
from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
}

to {
    opacity: 1;
    -moz-transform: translateY(0);        
    transform: translateY(0);        
}
}
@-ms-keyframes testim-content-in {
from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

to {
    opacity: 1;
    -ms-transform: translateY(0);        
    transform: translateY(0);        
}
}
@-o-keyframes testim-content-in {
from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
}

to {
    opacity: 1;
    -o-transform: translateY(0);        
    transform: translateY(0);        
}
}
@keyframes    testim-content-in {
from {
    opacity: 0;
    transform: translateY(100%);
}

to {
    opacity: 1;
    transform: translateY(0);        
}
}
@-webkit-keyframes testim-content-out {
from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

to {
    opacity: 0;
    -webkit-transform: translateY(-100%);        
    transform: translateY(-100%);        
}
}
@-moz-keyframes testim-content-out {
from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
}

to {
    opacity: 0;
    -moz-transform: translateY(-100%);        
    transform: translateY(-100%);        
}
}
@-ms-keyframes testim-content-out {
from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
}

to {
    opacity: 0;
    -ms-transform: translateY(-100%);        
    transform: translateY(-100%);        
}
}
@-o-keyframes testim-content-out {
from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
}

to {
    opacity: 0;
    transform: translateY(-100%);        
    transform: translateY(-100%);        
}
}
@keyframes    testim-content-out {
from {
    opacity: 1;
    transform: translateY(0);
}

to {
    opacity: 0;
    transform: translateY(-100%);        
}
}
@-webkit-keyframes testim-show {
from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -webkit-transform: scale(1);       
    transform: scale(1);       
}
}
@-moz-keyframes testim-show {
from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -moz-transform: scale(1);       
    transform: scale(1);       
}
}
@-ms-keyframes testim-show {
from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -ms-transform: scale(1);       
    transform: scale(1);       
}
}
@-o-keyframes testim-show {
from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -o-transform: scale(1);       
    transform: scale(1);       
}
}
@keyframes    testim-show {
from {
    opacity: 0;
    transform: scale(0);
}

to {
    opacity: 1;
    transform: scale(1);       
}
}
@-webkit-keyframes testim-hide {
from {
    opacity: 1;
    -webkit-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
}
@-moz-keyframes testim-hide {
from {
    opacity: 1;
    -moz-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
}
}
@-ms-keyframes testim-hide {
from {
    opacity: 1;
    -ms-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
}
}
@-o-keyframes testim-hide {
from {
    opacity: 1;
    -o-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
}
}
@keyframes    testim-hide {
from {
    opacity: 1;
    transform: scale(1);       
}

to {
    opacity: 0;
    transform: scale(0);
}
}
@media    all and (max-width: 300px) {
body {
    font-size: 14px;
}
}
@media    all and (max-width: 500px) {
.testim .arrow {
    font-size: 1.5em;
}

.testim .cont div p {
    line-height: 25px;
}}
@-webkit-keyframes scroll {
0% {
transform: translateX(0);
}
100% {
transform: translateX(calc(-250px * 7));
}
}

.slider .slide-track.two_a {
-webkit-animation: scrolla 40s linear infinite;
animation: scrolla 40s linear infinite;
display: flex;
width: calc(313px * 11);
}
@-webkit-keyframes scrolla {
0% {
transform: translateX(0);
}
100% {
transform: translateX(calc(250px * 7));
}
}

.slider {
 height: 43px;
margin: auto;
position: relative;
width: 100%;
margin-bottom: 7%;
}
.slider::after {
right: 0;
top: 0;
transform: rotateZ(180deg);
}
.slider::before {
left: 0;
top: 0;
}
.slider .slide-track {
-webkit-animation: scroll 40s linear infinite;
animation: scroll 40s linear infinite;
display: flex;
width: calc(313px * 11);
}
.slide i {
margin-right: 3%;
color: #fff;
}
.w-layout-grid.check-grid-large p {
text-align: center;
margin-top: 4%;font-size: 18px;
font-style: italic;
}
.slider .slide {
   height: 38px;
width: 338px;
margin: 0% 8px;
background: #f27013;
color: #fff;
padding: 6px;
border-radius: 5px;
box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
display: flex;
align-items: center;
justify-content: center;
font-size: 19px;
}
/*@font-face {*/
/*  font-family: FeelfreePersonalUseRegular;*/
/*  src: url(https://instabill.co/public/front/fonts/FeelfreePersonalUseRegular.ttf);*/
/*}*/
.price-card {
width: 28%;
float: left;
background-color: #fff;
border-radius: 25px;
padding: 32px 34px;
margin-right: 2%;
height: 508px;
}
.w-layout-grid.check-grid-large {
margin-top: 2%;
}
.price-caption {    margin-top: 0px;
font-size: 49px;
margin-bottom: 0px;
text-align: center;
line-height: 40px;
font-weight: bold;
}
.price-top-wrap h1 {
    margin-top: 0;
margin-bottom: 0;
font-size: 35px;
font-weight: 700;
line-height: 44px;
position: relative;
color: #fff;
display: inline-block;
}
.recommend-tag {
    color: #231d4f;
background-color: #fff;
border-radius: 50px;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 19px;
display: flex;
color: #fa8832;
font-size: 13px;
font-weight: 600;
float: right;
}
.price-card-dark {
  width: 99%;
float: left;
color: #fff;
background: linear-gradient(85deg, #f66a59, #ffae00);
border-radius: 25px;
padding: 32px 0px;
position: relative;
box-shadow: 15px 14px 5px rgba(11, 125, 125, .15);
/* height: 508px; */
text-align: center;
}

body {
   background: #fff9f2;
padding-bottom: 5%;
}
.clear{clear:both;}
.title-wrap {
width: 100%;
color: #7b88a8;
text-align: center;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 3%;
display: flex;
position: relative;
padding: 5% 0% 0%;
}
a.button-price.w-button {
width: 220px;
color: #fff;
text-align: center;
/* background: linear-gradient(45deg, rgba(255,101,165,1) 0%,rgba(255,101,165,1) 13%,rgba(255,107,154,1) 35%,rgba(255,134,106,1) 100%); */
border-radius: 100px;
margin-top: 16px;
padding: 8px 1%;
font-size: 15px;
font-weight: 500;
}
img.price-illustration {
position: absolute;
top: auto;
bottom: -8%;
left: auto;
right: -8%;
width: 204px;
}
.button-price.max-width {
max-width: 239px;
color: #231d4f!important;
background-color: #fff!important;

}
.feature-wrap {
  min-height: 10px;
grid-template-rows: auto auto auto;
grid-template-columns: 1fr;
align-content: start;
margin-top: 9px;
margin-bottom: 16px;
display: flex;

}

.w-layout-grid.check-grid {}
.content-h1 {
   z-index: 2;
color: #000;
letter-spacing: -.04em;
margin-top: 0;
margin-bottom: 20px;
font-size: 52px;
font-weight: 700;
line-height: 1.1;
position: relative;
text-transform: uppercase;
}
.subtitle {    color: rgb(8 8 8);
font-size: 24px;
font-weight: 400;
line-height: 31px;
}
.price-card {
background-color: #fff;
border-radius: 25px;
padding: 32px 34px;
}
.price-top-wrap {
min-height: 52px;
align-items: center;
text-align: left;
}
.check {
width: 22px;
height: 22px;
background-color: #eeeeee4a;
background-image: url(https://instabill.co/public/images/check.svg);
background-position: 0 0;
background-size: cover;
border-radius: 50%;
margin-right: 10px;
padding: 6px 0 5px;
}
a.button-price.w-button.pp {
margin-top: 2%;
display: inline-block;
margin-right: 1%;text-decoration: none;
}
.price-caption i {
color: #fc9325;
font-size: 14px;
font-weight: 600;
}
.ha{    background: #fff;
margin-top: 12%!important;
display: inline-block;
padding: 7px 2%!important;
width: 108%!important;}
.money_l {
width: 35%;
float: left;
}

.money_r {
width: 65%;
float: left;
padding-left: 5%;
}

.money_back {
padding: 8% 0%;
}

.money_r h2 {
z-index: 2;
color: #231d4f;
letter-spacing: -.04em;
margin-top: 0;
margin-bottom: 20px;
font-size: 30px;
font-weight: 700;
line-height: 1.1;
position: relative;
}

.money_r p {
color: #333;
font-size: 18px;
}

.overflow {
position: fixed;
width: 100%;
height: 100%;
top: 0;display:none;
background: #0000002b;
z-index: 9;
}

.overflow form {
width: 29%;
margin: auto;
margin-top: 5%;
background: #fff;    position: relative;
border-radius: 10px;
padding: 2%;
}


.in_A label {
width: 100%;
font-size: 15px;
font-weight: 500;
}

.in_A input {
width: 100%;
padding: 3%;
border: 1px solid #eee;
border-radius: 5px;
}

.in_A {
margin-top: 3%;
}
button.pay_i {
background: linear-gradient(45deg, rgba(255,101,165,1) 0%,rgba(255,101,165,1) 13%,rgba(255,107,154,1) 35%,rgba(255,134,106,1) 100%);
color: #fff;
border: 0px;
padding: 3%;
width: 100%;
border-radius: 5px;
font-size: 18px;
margin-top: 5%;
}

span.clse {
background: linear-gradient(45deg, rgba(255,101,165,1) 0%,rgba(255,101,165,1) 13%,rgba(255,107,154,1) 35%,rgba(255,134,106,1) 100%);
color: #fff;
width: 28px;
height: 28px;
cursor: pointer;
display: block;
position: absolute;
top: -1%;
right: -3%;
text-align: center;
font-size: 18px;
border-radius: 50px;
}

.color_change {    font-weight: 600;
color: #ffae00;
font-size: 29px;
}
.subtitle p {
   /*font-family: FeelfreePersonalUseRegular;*/
display: inline;
font-size: 30px;
color: #000;
line-height: 44px;
}
.price-top-wrap h1 img {
position: absolute;
top: -63%;
right: -92px;
width: 85px!important;
}
@media(max-width:768px){
.overflow form {
  width: 93%;
padding: 5%;
}.container1 {
    width: 98% !important;}

.righta h2 {
color: #000;
font-size: 32px;}
.container1 {
width: 94%;}
.righta {
position: relative;
width: 100%;}
.lefta {
width: 100%;}
.marquee1 {
margin-bottom: 2px;
}
.overflow {
background: #000000b5;}
span.clse {
top: -3%;}
.price-top-wrap {
margin-top: 3%;
}
.price-top-wrap img {
width: 39px!important;
margin-top: -18px!important;
margin-left: 1%!important;
}
img.imga {    width: 80px!important;
margin-top: 3%!important;
}
.price-card {
width: 28%;
float: left;
background-color: #fff;
border-radius: 25px;
padding: 32px 34px;
margin-right: 2%;
height: auto;
margin-bottom: 5%;
}
.content-h1 {
font-size: 25px;}
.price-card {    width: 100%;
padding: 5% 3%!important;}
a.button-price.w-button.pp {
margin-top: 5%;margin-left: 15%;
display: block;}
img.price-illustration {
  bottom: 0%;
left: auto;
right: 1%;
width: 29%;
}
body {
overflow-x: hidden;
}
.w-layout-grid.check-grid-large p {
width: 84%;}
.price-card-dark {
width: 100%;
border-radius: 25px;
padding: 16px 0px;}
.money_r {
width: 100%;
float: left;
padding-left: 0%;
}
.money_l {
width: 100%;
float: left;
}
.subtitle p {
display: inline;
font-size: 24px;
color: #000;
line-height: 38px;
}
.price-top-wrap h1 img {
position: absolute;
top: -21%;
right: 7px;
width: 80px!important;
}
.price-top-wrap h1 {
font-size: 27px;
line-height: 18px;
}
.button-price.max-width {
  max-width: 76%;
width: 100%;    margin-top: 5%!important;
display: block;}
.recommend-tag {
   font-size: 12px;
font-weight: 600;
width: 30%;
padding: 1% 1px!important;
}
}


.eff {
 text-transform: uppercase;
text-decoration: none;
font-family: sans-serif;
box-sizing: border-box;
background: linear-gradient(90deg, yellow, orange, red, violet, purple, blue, yellow);
background-size: 400%;
}
.eff {
animation: animate 8s linear infinite;
}
@keyframes  animate {
0% {
background-position: 0%;
}
100% {
background-position: 400%;
}
}

.eff:before {
content: "";
position: absolute;
top: -5px;
left: -5px;
right: -5px;
bottom: -5px;
z-index: -1;
/*background: linear-gradient(45deg, #f15523, #ef3224, #7c3697);*/
background-size: 400%;
border-radius: 40px;
opacity: 0;
transition: 0.5%;
}
.eff:hover:before {
filter: blur(20px);
opacity: 1;
animation: animate 8s linear infinite;
}
a.button-price.w-button.pp.ab {
background: linear-gradient(45deg, rgba(255,101,165,1) 0%,rgba(255,101,165,1) 13%,rgba(255,107,154,1) 35%,rgba(255,134,106,1) 100%);
}
.slider.two {
margin-left: 16%;
}

.slider.three {
margin-left: -7%;
}
img.load_show {
display: none;
}
</style>
